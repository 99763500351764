/*
 * Copyright 2019 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except
 * in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { FirebaseAuth, UserCredential } from '@firebase/auth-types'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import * as ciap from 'gcip-iap'
import React from 'react'
import { Loading } from '../gui/Loading'
import OAuthProvider = firebase.auth.OAuthProvider
import { signInWithRedirect } from 'firebase/auth'
import { Container } from '../gui/Card'

const DEFAULT_REDIRECT_URL: string =
    process.env.REACT_APP_DEFAULT_REDIRECT_URI || ''
const AUTH0_PROVIDER: string = process.env.REACT_APP_OATH_PROVIDER || ''

class Login extends React.Component<{}> implements ciap.AuthenticationHandler {
    private ciapInstance: any
    private config: any

    constructor(props: {}) {
        super(props)
        this.config = {
            apiKey: process.env.REACT_APP_APIKEY,
            authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        }
    }

    componentDidMount() {
        this.ciapInstance = new ciap.Authentication(this)
        const p = this.ciapInstance.start()
        return p
    }

    public getAuth(apiKey: string, tenantId: string): FirebaseAuth {
        let auth = null
        if (apiKey !== this.config.apiKey) {
            throw new Error('Invalid project!')
        }
        try {
            auth = firebase.app(tenantId || undefined).auth()
            // Tenant ID should be already set on initialization below.
        } catch (e) {
            const app = firebase.initializeApp(
                this.config,
                tenantId || '[DEFAULT]',
            )

            auth = app.auth()
            auth.tenantId = tenantId || null
        }
        return auth as any
    }

    public startSignIn(auth: FirebaseAuth): Promise<UserCredential> {
        return new Promise((resolve, reject) => {
            const provider = new OAuthProvider(AUTH0_PROVIDER)
            signInWithRedirect(auth, provider)
        })
    }

    public completeSignOut(): Promise<void> {
        this.signOut()
        return Promise.resolve()
    }

    private signOut() {
        window.location.href = `${DEFAULT_REDIRECT_URL}?gcp-iap-mode=CLEAR_LOGIN_COOKIE`
    }

    render(): JSX.Element {
        return (
            <Container>
                <Loading />
            </Container>
        )
    }
}

export { Login }
