import { ReactNode, useEffect } from 'react'
import {
    BrowserRouter,
    Route,
    Routes,
    useNavigate,
    useSearchParams,
} from 'react-router-dom'
import { Login } from '../login/Login'

const DEFAULT_REDIRECT_URI = `${process.env.REACT_APP_DEFAULT_REDIRECT_URI}`

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <AuthParamsCheckHandler>
                            <Login />
                        </AuthParamsCheckHandler>
                    }
                />
            </Routes>
        </BrowserRouter>
    )
}

interface AuthParamsChildrenProps {
    children?: ReactNode
}

const AuthParamsCheckHandler: React.FC<AuthParamsChildrenProps> = ({
    children,
}) => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        /** check for apiKey, mode, redirect_uri, state and tid.
         * if any of them are missing, redirect to DEFAULT_REDIRECT_URI
         * this will force IAP to add the correct params
         */
        const apiKey = searchParams.get('apiKey') ?? ''
        const mode = searchParams.get('mode') ?? ''
        const tenantId = searchParams.get('tid') ?? ''

        if (!apiKey || !mode || !tenantId) {
            window.location.href = DEFAULT_REDIRECT_URI
        }
    }, [navigate, searchParams])

    return <>{children}</>
}

export { Router }
