import '@fontsource/poppins/300.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/700.css'
import React from 'react'
import { App } from './App'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'

const app = document.getElementById('root')
if (!app) throw new Error('No root container found!')

const root = createRoot(app!)
root.render(
    <StrictMode>
        <App />
    </StrictMode>,
)
