import { styled } from '@mui/material'

export const Container = styled('div')`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: 600px) {
        flex-grow: 0.5;
    }
`

export const Card = styled('div')`
    background: ${(props) => props.theme.custom.white};
    border-radius: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

    @media only screen and (min-width: 600px) {
        border-radius: 4px;
        box-shadow: 0px 0px 8px 0px
            ${(props) => props.theme.custom.purple.shadow};
        flex-grow: 0;
        display: block;
        width: 625px;
    }
`
export const CardContent = styled('div')`
    @media only screen and (min-width: 600px) {
        padding: 10px 96px 25px 96px;
    }
`

export const CardForm = styled('form')`
    padding: 10px 96px 25px 96px;

    @media only screen and (max-width: 600px) {
        padding: 0px;
        width: 85%;
    }
`
