import { styled, ThemeProvider } from '@mui/material/styles'
import React from 'react'
import { Router } from './components/router/Router'
import theme from './utils/theme'

function App() {
    return (
        <ThemeProvider theme={theme}>
            <AppContainer>
                <Router />
            </AppContainer>
        </ThemeProvider>
    )
}

export { App }

const AppContainer = styled('div')`
    text-align: left;
    background-color: #f7f7f4;
    margin: 0;
    color: ${(props) => props.theme.palette.text.primary};
    font-family: ${(props) => props.theme.typography.fontFamily};
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
`
