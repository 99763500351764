import { styled } from '@mui/material'
import React from 'react'

const Loading = () => (
    <Container data-testid="loading-spinner">
        <LoaderContainer>
            <Rectangle />
            <Rectangle className="offset-1 primary" />
            <Rectangle className="offset-2" />
            <Rectangle className="offset-3" />
        </LoaderContainer>
    </Container>
)

export { Loading }

const Container = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
`

const LoaderContainer = styled('div')`
    height: 40px;
    width: 40px;

    display: flex;
    flex-wrap: wrap;

    animation-name: turn;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;

    @keyframes turn {
        0% {
            transform: rotate(-90deg);
        }
        20% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(0deg);
        }
        80% {
            transform: rotate(90deg);
        }
        100% {
            transform: rotate(90deg);
        }
    }
`

const Rectangle = styled('div')`
    height: 50%;
    width: 50%;

    background: ${(props) => props.theme.custom.purple.main};

    animation-name: grow;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;

    &.offset-1 {
        animation-delay: 100ms;
    }

    &.offset-2 {
        animation-delay: 200ms;
    }

    &.offset-3 {
        animation-delay: 300ms;
    }

    &.primary {
        background: ${(props) => props.theme.custom.yellow.main};
    }

    @keyframes grow {
        0% {
            transform: rotate(0);
        }
        15% {
            transform: scale(1);
        }
        45% {
            transform: scale(1);
        }
        50% {
            transform: scale(0);
        }
        100% {
            transform: scale(0);
        }
    }
`
