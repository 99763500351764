import { createTheme } from '@mui/material/styles'
import shadows, { Shadows } from '@mui/material/styles/shadows'

const paymenttoolsColors = {
    purple: {
        main: '#2E3859',
        light: '#869AD8',
        medium: '#5669A6',
        dark: '#212840',
        table: '#C2CCEB',
        shadow: '#2E385926',
    },
    yellow: {
        main: '#FAB608',
        light: '#FFC63C',
        selection: '#FFE29D',
        dark: '#DB9F07',
    },
    sand: {
        main: '#F7F7F4',
        table: '#F2F2F2',
        fade: '#DEDEDB',
        dawn: '#CDCDCA',
        extra: '#7F8390',
    },
    white: '#FFFFFF',
    error: {
        main: '#C64756',
        light: '#F4DADD',
    },
    success: {
        main: '#289672',
        light: '#D4EAE3',
    },
}

const theme = createTheme({
    custom: {
        ...paymenttoolsColors,
    },
    customSpacing: {
        xs: '0.25em',
        sm: '0.5em',
        md: '1em',
        lg: '1.5em',
        xl: '2em',
        xxl: '3em',
    },
    palette: {
        text: {
            primary: paymenttoolsColors.purple.dark,
        },
        primary: {
            main: paymenttoolsColors.yellow.main,
            contrastText: paymenttoolsColors.purple.main,
        },
        secondary: {
            main: paymenttoolsColors.purple.main,
        },
        error: {
            main: paymenttoolsColors.error.light,
            contrastText: paymenttoolsColors.error.main,
        },
        success: {
            main: paymenttoolsColors.success.light,
            contrastText: paymenttoolsColors.success.main,
        },
        warning: {
            main: paymenttoolsColors.yellow.selection,
            contrastText: paymenttoolsColors.yellow.dark,
        },
        info: {
            main: paymenttoolsColors.sand.table,
            contrastText: paymenttoolsColors.sand.extra,
        },
    },
    typography: {
        fontFamily: 'Poppins, Helvetica, sans-serif',
        button: {
            textTransform: 'none',
        },
    },
    shadows: shadows.map(() => 'none') as Shadows,
    components: {
        MuiButton: {
            styleOverrides: {
                outlined: {
                    borderColor: paymenttoolsColors.yellow.main,
                    backgroundColor: paymenttoolsColors.white,
                },
                root: {
                    '&.Mui-disabled': {
                        backgroundColor: paymenttoolsColors.yellow.selection,
                        color: paymenttoolsColors.purple.table,
                    },
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    fontFamily: 'Poppins, Helvetica, sans-serif',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: paymenttoolsColors.purple.main,
                    color: paymenttoolsColors.white,
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiFormLabel-root.Mui-error': {
                        color: paymenttoolsColors.error.main,
                    },
                    '& .Mui-error > .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${paymenttoolsColors.error.main} !important`,
                    },
                    '& .MuiFormHelperText-root.Mui-error': {
                        color: paymenttoolsColors.error.main,
                    },
                },
            },
        },
        MuiAlert: {
            variants: [
                {
                    props: { severity: 'error' },
                    style: {
                        backgroundColor: paymenttoolsColors.error.main,
                        color: paymenttoolsColors.white,
                        padding: '16px',
                    },
                },
                {
                    props: { severity: 'success' },
                    style: {
                        backgroundColor: paymenttoolsColors.success.main,
                        color: paymenttoolsColors.white,
                        padding: '16px',
                    },
                },
            ],
        },
    },
})

export default theme

declare module '@mui/material/styles' {
    interface Theme {
        custom: {
            purple: {
                main: React.CSSProperties['color']
                light: React.CSSProperties['color']
                medium: React.CSSProperties['color']
                dark: React.CSSProperties['color']
                table: React.CSSProperties['color']
                shadow: React.CSSProperties['color']
            }
            yellow: {
                main: React.CSSProperties['color']
                light: React.CSSProperties['color']
                selection: React.CSSProperties['color']
                dark: React.CSSProperties['color']
            }
            sand: {
                main: React.CSSProperties['color']
                table: React.CSSProperties['color']
                fade: React.CSSProperties['color']
                dawn: React.CSSProperties['color']
                extra: React.CSSProperties['color']
            }
            white: React.CSSProperties['color']
            error: {
                main: React.CSSProperties['color']
                light: React.CSSProperties['color']
            }
            success: {
                main: React.CSSProperties['color']
                light: React.CSSProperties['color']
            }
        }
        customSpacing: {
            xs: string
            sm: string
            md: string
            lg: string
            xl: string
            xxl: string
        }
    }

    interface ThemeOptions {
        custom: {
            purple: {
                main: React.CSSProperties['color']
                light: React.CSSProperties['color']
                medium: React.CSSProperties['color']
                dark: React.CSSProperties['color']
                table: React.CSSProperties['color']
            }
            yellow: {
                main: React.CSSProperties['color']
                light: React.CSSProperties['color']
                selection: React.CSSProperties['color']
                dark: React.CSSProperties['color']
            }
            sand: {
                main: React.CSSProperties['color']
                table: React.CSSProperties['color']
                fade: React.CSSProperties['color']
                dawn: React.CSSProperties['color']
                extra: React.CSSProperties['color']
            }
            white: React.CSSProperties['color']
            error: {
                main: React.CSSProperties['color']
                light: React.CSSProperties['color']
            }
            success: {
                main: React.CSSProperties['color']
                light: React.CSSProperties['color']
            }
        }
        customSpacing: {
            xs: string
            sm: string
            md: string
            lg: string
            xl: string
            xxl: string
        }
    }
}
